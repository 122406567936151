@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy-SemiBold.eot');
  src: local('Gilroy SemiBold'), local('Gilroy-SemiBold'),
    url('/fonts/Gilroy-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Gilroy-SemiBold.woff') format('woff'), url('/fonts/Gilroy-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy-Light.eot');
  src: local('Gilroy Light'), local('Gilroy-Light'),
    url('/fonts/Gilroy-Light.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Gilroy-Light.woff') format('woff'), url('/fonts/Gilroy-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy-Regular.eot');
  src: local('Gilroy Regular'), local('Gilroy-Regular'),
    url('/fonts/Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Gilroy-Regular.woff') format('woff'), url('/fonts/Gilroy-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy-Medium.eot');
  src: local('Gilroy Medium'), local('Gilroy-Medium'),
    url('/fonts/Gilroy-Medium.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Gilroy-Medium.woff') format('woff'), url('/fonts/Gilroy-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy-UltraLight.eot');
  src: local('Gilroy UltraLight'), local('Gilroy-UltraLight'),
    url('/fonts/Gilroy-UltraLight.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Gilroy-UltraLight.woff') format('woff'),
    url('/fonts/Gilroy-UltraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy-Bold.eot');
  src: local('Gilroy Bold'), local('Gilroy-Bold'),
    url('/fonts/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Gilroy-Bold.woff') format('woff'), url('/fonts/Gilroy-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Mono';
  src: url('/fonts/Robotomono.ttf');
  src: local('Roboto Mono'), local('RobotoMonoRegular'), url('/fonts/Robotomono.woff2') format('woff2'),
    url('/fonts/Robotomono.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Mono';
  src: url('/fonts/Robotomonomedium.ttf');
  src: local('Roboto Mono Medium'), local('RobotoMonoMedium'),
    url('/fonts/Robotomonomedium.woff2') format('woff2'), url('/fonts/Robotomonomedium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, Helvetica, 'Roboto', 'Open Sans', 'Source Sans Pro',
    'Segoe UI', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
  font-family: 'Gilroy', 'Roboto', 'Open Sans', 'Source Sans Pro', Helvetica, -apple-system,
    BlinkMacSystemFont, 'Segoe UI', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button,
input {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
  line-height: 1.4;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  margin-bottom: 0;
  margin-top: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style-type: none;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

strong {
  font-weight: 600;
}

/* a {
  text-decoration: none;
} */

button:focus,
input:focus {
  outline: none;
}

img {
  text-indent: -10000px;
}

.apexcharts-canvas .apexcharts-yaxistooltip.apexcharts-theme-dark,
.apexcharts-canvas .apexcharts-xaxistooltip.apexcharts-theme-dark {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.28);
  padding: 1px 3px;
  background: #f35531;
  border-radius: 2px;
  border: 0;
  font-family: 'Gilroy', 'Roboto', 'Open Sans', 'Source Sans Pro', Helvetica, -apple-system,
    BlinkMacSystemFont, 'Segoe UI', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}
.apexcharts-canvas .apexcharts-yaxistooltip.apexcharts-theme-dark::before,
.apexcharts-canvas .apexcharts-yaxistooltip.apexcharts-theme-dark::after {
  border-width: 4px;
  margin-top: -3px;
  border-left-color: #f35531;
}

.apexcharts-canvas .apexcharts-xaxistooltip.apexcharts-theme-dark::before,
.apexcharts-canvas .apexcharts-xaxistooltip.apexcharts-theme-dark::after {
  border-width: 4px;
  margin-left: -4px;
  border-bottom-color: #f35531;
}

input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
  margin: 0;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(-50px);
  }
}
